<template>
  <div class="n">
    <nav class="navbar navbar-expand-md navbar-dark fixed-top">
      <div class="container">
        <router-link class="navbar-brand" to="/">
          <img
            src="@/assets/img/logo.png"
            alt="Logo"
            width="40"
            height="40"
            class="me-1"
          />
          Yapim Marelan
        </router-link>
        <button
          class="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarNavDropdown"
          aria-controls="navbarNavDropdown"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span class="navbar-toggler-icon"></span>
        </button>
        <div class="collapse navbar-collapse" id="navbarNavDropdown">
          <ul class="navbar-nav ms-auto">
            <li class="nav-item">
              <router-link class="nav-link" aria-current="page" to="/" exact
                >Beranda</router-link
              >
            </li>
            <li class="nav-item">
              <router-link class="nav-link" to="/berita">Berita</router-link>
            </li>
            <li class="nav-item">
              <router-link class="nav-link" to="/agenda">Agenda</router-link>
            </li>
            <li class="nav-item">
              <router-link class="nav-link" to="/kegiatan"
                >Kegiatan</router-link
              >
            </li>
            <li class="nav-item">
              <a class="nav-link" href="#footer">Kontak</a>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  </div>
</template>

<script>
export default {
  name: "Navbar",
};
</script>

<style scoped lang="scss">
@import "@/scss/_variable.scss";

.navbar {
  background-color: $primaryColor;
  .navbar-brand {
    font-family: "Maven Pro", Helvetica, Arial, sans-serif;
    font-weight: 500;
  }
  ul li {
    color: white;
    margin-right: 1em;
    @media (min-width: 992px) {
      margin-right: 2.5em;
    }
    a.nav-link {
      font-family: "Maven Pro", Helvetica, Arial, sans-serif;
      font-weight: 400;
      &.active {
        font-weight: 500;
      }
    }
  }
}
</style>
